// extracted by mini-css-extract-plugin
export const root = "PlasmicFooter-module--root--oyEUz";
export const box___9T78H = "PlasmicFooter-module--box___9T78H--3LUIt";
export const box___96JFl = "PlasmicFooter-module--box___96JFl--1CbvM";
export const box__bHiXt = "PlasmicFooter-module--box__bHiXt--2baHL";
export const iconLink___2IAgZ = "PlasmicFooter-module--iconLink___2IAgZ--yFXzL";
export const svg__qrjj = "PlasmicFooter-module--svg__qrjj--1aoBi";
export const box___2Hsao = "PlasmicFooter-module--box___2Hsao--eRV7Z";
export const link__fLeD = "PlasmicFooter-module--link__fLeD--2b03p";
export const box__bqm8J = "PlasmicFooter-module--box__bqm8J--2e7Z5";
export const link__lVcB = "PlasmicFooter-module--link__lVcB--1ImnR";
export const box__bTafa = "PlasmicFooter-module--box__bTafa--Dxn1d";
export const box__bzJws = "PlasmicFooter-module--box__bzJws--1ZSCX";
export const box__yhiJp = "PlasmicFooter-module--box__yhiJp--1-iox";
export const link__mbHnC = "PlasmicFooter-module--link__mbHnC--2xF5F";
export const link__gLt0 = "PlasmicFooter-module--link__gLt0--2MCpc";
export const link__f2HoL = "PlasmicFooter-module--link__f2HoL--fqqJx";
export const link__ykUXy = "PlasmicFooter-module--link__ykUXy--1MKNM";
export const link__rDfp1 = "PlasmicFooter-module--link__rDfp1--1H1YP";
export const box__chv5I = "PlasmicFooter-module--box__chv5I--9i0GO";
export const box__dubvI = "PlasmicFooter-module--box__dubvI--2TgJC";
export const link__vGoDk = "PlasmicFooter-module--link__vGoDk--2QW30";
export const link__y13Pe = "PlasmicFooter-module--link__y13Pe--2_1LS";
export const link__yEb6H = "PlasmicFooter-module--link__yEb6H--23Snv";
export const link__rw31Q = "PlasmicFooter-module--link__rw31Q--1AEXC";
export const link__ml2Ai = "PlasmicFooter-module--link__ml2Ai--3ZmEe";
export const box__hoc6X = "PlasmicFooter-module--box__hoc6X--2uGnn";
export const box__zx622 = "PlasmicFooter-module--box__zx622--1Pr7R";
export const link__ofspb = "PlasmicFooter-module--link__ofspb--3vs1B";
export const link___0Zd1B = "PlasmicFooter-module--link___0Zd1B--yx8hd";
export const link__nKnwD = "PlasmicFooter-module--link__nKnwD--LrUiK";
export const link__sHMr1 = "PlasmicFooter-module--link__sHMr1--3eUpu";
export const link__wkgDi = "PlasmicFooter-module--link__wkgDi--6HXvP";
export const box__gRjTr = "PlasmicFooter-module--box__gRjTr--1HNKC";
export const box__vlIft = "PlasmicFooter-module--box__vlIft--2EMoZ";
export const box___88YUr = "PlasmicFooter-module--box___88YUr--26vNW";
export const box__gvCz5 = "PlasmicFooter-module--box__gvCz5--3QNc8";
export const textbox = "PlasmicFooter-module--textbox--1O3a3";
export const button = "PlasmicFooter-module--button--27DKR";
export const box__vdwgu = "PlasmicFooter-module--box__vdwgu--2TGYI";
export const box__rPu8U = "PlasmicFooter-module--box__rPu8U--2hnva";
export const box__tE7R = "PlasmicFooter-module--box__tE7R--1m2TJ";
export const link___0Stmo = "PlasmicFooter-module--link___0Stmo--1O3oI";
export const link___5VYfr = "PlasmicFooter-module--link___5VYfr--2OgWo";
export const box__y0NHr = "PlasmicFooter-module--box__y0NHr--hwE6t";
export const box__qjK9M = "PlasmicFooter-module--box__qjK9M--3rhuI";
export const iconLink__oyltA = "PlasmicFooter-module--iconLink__oyltA--35VBh";
export const svg__gsb2Y = "PlasmicFooter-module--svg__gsb2Y--29E7J";
export const iconLink__qiaSq = "PlasmicFooter-module--iconLink__qiaSq--2hABF";
export const svg___1VgcN = "PlasmicFooter-module--svg___1VgcN---NpyF";
export const iconLink___1HgOu = "PlasmicFooter-module--iconLink___1HgOu--c-tm9";
export const svg__xqGT = "PlasmicFooter-module--svg__xqGT--1NTTG";